.dashboard {
    padding: 0 20px;
    display: flex;
    flex-direction: row;

    .sidebar {
        padding: 10px;
        width: 20vw;
    }

    .admin-content {
        padding: 10px;
        width: 80vw;
    }
}
